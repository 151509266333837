import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgC from "../../../assets/image/time-clocks/record-shift-hours.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 ">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="5" className="pr-lg-9">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  Record employee hours
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  Camelo automatically records, saves, and stores employee timesheets for you.
                </p>
              </div>
            </Col>
            <Col xs="10" lg="6" className="offset-lg-1 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                data-aos="fade-left"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="Record shifts hours app" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
