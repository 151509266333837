import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgC from "../../../assets/image/time-clocks/view-realtime-attendance.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 ">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="5" className="pr-lg-9">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  Get a bird’s eye view of your workforce
                </h2>
                <p className="gr-text-8 pl-0 ml-0">
                  <div className="d-flex align-items-start py-2">
                    <div className="gr-text-8 text-primary mr-6 mt-2 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <div>who’s working, where they’re working, and what they’re working on</div>
                  </div>
                  <div className="d-flex align-items-start py-2">
                    <div className="gr-text-8 text-primary mr-6 mt-2 d-flex align-items-center">
                      <i className="icon icon-check-simple"></i>
                    </div>
                    <div>who’s on, who’s off, and who’s running late</div>
                  </div>
                </p>
              </div>
            </Col>
            <Col xs="10" lg="6" className="offset-lg-1 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                data-aos="fade-left"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="View realtime attendance for shift work" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
