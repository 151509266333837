import React from "react";

import {Container, Row, Col} from "react-bootstrap";

import imgC from "../../../assets/image/time-clocks/location-based-timeclocks.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-12 pb-5 pt-lg-19 pb-lg-23 bg-default-1">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="6" className="mb-10 mb-lg-0 order-2 order-lg-1">
              <div
                className="content-img img-group-2"
                data-aos="fade-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="Employees clock in and clock out using location-based, Wi-Fi, and facial validation." />
              </div>
            </Col>
            <Col xs="10" lg="6" xl="5" className="order-1 order-lg-2">
              <h2 className="gr-text-4 mb-6">Clock in by different ways</h2>
              <p className="gr-text-8 pb-12">
                Employees clock in and clock out using location-based, Wi-Fi, and facial validation.
              </p>
              <div className="content-widget-1">
                <div className="media single-widget mb-7">
                  <div className="count circle-sm text-primary gr-bg-blue-opacity-1 mr-8">
                    <i class="icon icon-check-simple"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Location-based</h3>
                    <p className="gr-text-9">
                      GPS-based time clock ensures that your employees clock in at the right location.{" "}
                    </p>
                  </div>
                </div>
                <div className="media single-widget mb-7">
                  <div className="count circle-sm gr-bg-blue-opacity-1 mr-8">
                    <i class="text-primary icon icon-check-simple"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Wi-Fi</h3>
                    <p className="gr-text-9">
                      Employees clock in by connecting to the location's Wi-Fi network.{" "}
                    </p>
                  </div>
                </div>
                <div className="media single-widget mb-7">
                  <div className="count circle-sm gr-bg-blue-opacity-1 mr-8">
                    <i class="text-primary icon icon-check-simple"></i>
                  </div>
                  <div className="media-body">
                    <h3 className="gr-text-7">Facial Recognition</h3>
                    <p className="gr-text-9">
                      Employees clock in by taking a selfie at the location.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
