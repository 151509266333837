import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgC from "../../../assets/image/time-clocks/timely-reminder-employee-shifts.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 ">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="6" className="mt-10 mt-lg-0 order-2 order-lg-1">
              <div
                className="content-img img-group-1 ml-xl-24"
                data-aos="fade-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="Timely reminders for shift work" />
              </div>
            </Col>
            <Col xs="10" lg="5" className="offset-lg-1 pr-lg-9 order-1 order-lg-2">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  Receive timely reminders
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  Employees get notified when they’re running late or forget to clock in.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
