import React from "react";

import {Container, Row, Col} from "react-bootstrap";

import imgRealtimeAttendance from "../../../assets/image/time-clocks/realtime-attendance.svg";
import imgRecordHours from "../../../assets/image/time-clocks/record-and-store-employee-hours.svg";
import imgExportTimesheets from "../../../assets/image/time-clocks/export-timesheets.svg";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="feature-section bg-default-11 py-13 py-lg-13 "
        data-aos="zoom-out-up"
        data-aos-duration="750"
        data-aos-delay="400"
      >
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="4" className="mb-8 mb-lg-0">
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={imgRecordHours} style={{height: "4rem"}} alt="Record and store employee shifts" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7">Record and store employee hours automatically</h5>
                </div>
              </div>
            </Col>
            <Col xs="10" lg="4" className="mb-8 mb-lg-0">
              <div className="feature-widget-1">
                <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                  <img src={imgExportTimesheets} style={{height: "4rem"}} alt="Export timesheets for payroll" />
                  <div className="media-body mt-10">
                    <h5 className="gr-text-7 mb-7">Export timesheets for accurate payroll</h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs="10" lg="4" className="mb-8 mb-lg-0">
              <div className="feature-widget-1">
                <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                  <img src={imgRealtimeAttendance} style={{height: "4rem"}} alt="Realtime attendance app" />
                  <div className="media-body mt-10">
                    <h5 className="gr-text-7 mb-7">Check real-time attendance anytime</h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
