import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

// import imgHero from "../../assets/image/l1/png/l1-hero-img.png";
import imgHero from "../../../assets/image/draft-banner.png";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="pb-11 pb-lg-14 pt-25 pt-lg-29 bg-default-1 position-relative">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col
              sm="10"
              md="9"
              lg="8"
              xl="8"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="100"
            >
              <div className="hero-content text-center">
                <h1 className="gr-text-3 mb-8">
                  The reliable time tracking app that saves you hours
                </h1>
                <p
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="900"
                  className="gr-text-6 lead"
                >
                  Keep employee's time and attendance under control with Camelo
                </p>
                {/* <div className="hero-btns d-flex flex-column flex-md-row justify-content-md-center mt-11"> */}
                {/*   <Button className="btn btn-primary with-icon gr-hover-y mb-6 mb-md-0 mr-md-7"> */}
                {/*     Start Scheduling Free */}
                {/*     <i className="fas fa-arrow-right gr-text-11"></i> */}
                {/*   </Button> */}
                {/* </div> */}
              </div>
            </Col>
            {/* <Col sm="10" className="mt-15 mt-lg-23"> */}
            {/*   <div */}
            {/*     className="hero-img" */}
            {/*     data-aos="fade-left" */}
            {/*     data-aos-duration="750" */}
            {/*     data-aos-delay="500" */}
            {/*   > */}
            {/*     <img className="img-fluid" src={imgHero} alt="" /> */}
            {/*   </div> */}
            {/* </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
