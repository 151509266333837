import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgC from "../../../assets/image/time-clocks/record-verify-approve-shift-timesheets.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 ">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="6" className="mt-10 mt-lg-0 order-2 order-lg-1">
              <div
                className="content-img img-group-1"
                data-aos="fade-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="Record, Verify, edit and approve shift timesheets" />
              </div>
            </Col>
            <Col xs="10" lg="5" className="offset-lg-1 pr-lg-9 order-1 order-lg-2">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  Verify, edit, and approve timesheets
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  Verify, edit, and approve timesheets at the end of each pay period to make sure timesheets are accurate.
                </p>
              </div>
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  Keep records of employee timesheets
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  Stay compliant by keeping records of employee timesheets, including edit history.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
