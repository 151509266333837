import React from "react";
import {Container, Row, Col} from "react-bootstrap";

import imgC from "../../../assets/image/time-clocks/time-clocks-app-for-restaurants.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-13 pt-lg-17 bg-default-6">
        <Container>
          <Row className="align-items-end justify-content-center">
            <Col lg="5" md="4" className="order-2 order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
                className="content-img"
              >
                <img className="w-100" src={imgC} alt="" />
              </div>
            </Col>
            <Col
              xs="10"
              lg="7"
              md="8"
              className="mt-9 mt-lg-0 px-xl-11 order-1 order-lg-2"
            >
              <div className="content-text">
                <h2 className="title gr-text-4 mb-7">
                  Why use the Camelo time tracking app?
                </h2>
                <p className="gr-text-8 mb-0">
                  With Camelo app, you’re not just saving time and money. You’re making life easier for your teams so they can focus on their most important tasks.
                </p>
              </div>
              <div className="content-widget mt-9 mb-11">
                <div className="single-widget d-flex align-items-start py-5">
                  <div className="widget-icon text-primary mr-6 gr-text-8 pt-2">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <h3 className="w-title gr-text-8 font-weight-bold mb-0">
                    Save yourself hours on tracking time and attendance manually
                  </h3>
                </div>
                <div className="single-widget d-flex align-items-start py-5">
                  <div className="widget-icon text-primary mr-6 gr-text-8 pt-2">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <h3 className="w-title gr-text-8 font-weight-bold mb-0">
                    Save your business from costly timesheet mistakes
                  </h3>
                </div>
                <div className="single-widget d-flex align-items-start py-5">
                  <div className="widget-icon text-primary mr-6 gr-text-8 pt-2">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <h3 className="w-title gr-text-8 font-weight-bold mb-0">
                    Give employees assurance that they're paid correctly
                  </h3>
                </div>
                <div className="single-widget d-flex align-items-start py-5">
                  <div className="widget-icon text-primary mr-6 gr-text-8 pt-2">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <h3 className="w-title gr-text-8 font-weight-bold mb-0">
                    Track and manage employee hours on any devices, anywhere
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={"fact-section py-12 pt-lg-20 pb-lg-17 bg-hover-yellow position-relative paperwapper"}>
        <Container>
          <Row className="justify-content-center">
            <Col xs="10">
              <div
                data-aos="fade-up"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <h3
                  className="gr-text-3 open-quote"
                >
                  I've saved at least 3 hours every week since using Camelo. It does the mundane work for me, while keeping important data accurate.
              </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
