import React, {useState} from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Collapse} from "react-bootstrap";

const QA = ({id, question, children}) => {
  const [openItem, setOpenItem] = useState(false);

  return (
    <div className="border rounded-10 mb-3 bg-white overflow-hidden">
      <a
        className="mb-0 btn py-8 pl-9 pr-7 border-bottom-0 bg-white accordion-trigger arrow-icon w-100"
        id={"heading-question-" + id}
        aria-controls={"collapse-" + id}
        onClick={() => setOpenItem(!openItem)}
        aria-expanded={openItem}
      >
        <div
          className="gr-text-7 font-weight-bold text-left text-blackish-blue p-0 "
        >
          {question}
        </div>
      </a>
      <Collapse in={openItem}>
        <div>
          <div className="card-body gr-color-blackish-blue-opacity-7 pt-0 pl-9 pr-15 gr-text-9 pb-9">
            <div className="pt-6">
              {children}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};


const Faq = () => {
  return (
    <>
      {/* <!-- Faq Area --> */}
      <div className="faq-section pb-12 pt-12 pb-lg-24 pt-lg-24">
        <Container>
          <Row className="justify-content-center">
            <Col xl="8" lg="9">
              <div className="px-md-12 text-center mb-9 mb-lg-18">
                <h2 className="title gr-text-3 mb-8">
                  Frequently Asked Questions
                  </h2>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xl="9" lg="9">
              <div className="accordion" id="accordionExample">
                <QA id="1" question="What is a time clock app?" >
                  A time clock app, also known as a time tracking app, is an app that allows employees to clock in and clock out of their shifts via different devices. It automatically records employee work hours and creates timesheets for payroll processing.
                </QA>
                <QA id="2" question="What is a timesheet app?" >
                  A timesheet app records the clock-in and clock-out time of employees and creates timesheets automatically, making it easier for employers to track employees' total hours and run payroll.
                </QA>
                <QA id="3" question="What is a time and attendance tracking app?" >
                  It's a combination of a time clock app and a timesheet app.
                </QA>
                <QA id="4" question="Is Camelo a time and attendance tracking app, a time clock app, or a timesheet app?" >
                  All! Camelo lets employees clock in and clock out right on the app, so you can record their attendance. Employee work hours are recorded automatically, and you can export timesheets for payroll later on.
                </QA>
                <QA id="5" question="Why should a business use a time and attendance tracking app?" >
                  Time and attendance tracking can be time- and energy-consuming if you use paper timesheets, or spreadsheets on Excel and Google Sheets. Software like Camelo streamlines the whole process, making it easier for you to track your employee hours and attendance. Plus, Camelo offers a free plan with basic features and a free trial for a paid plan.
                </QA>
                <QA id="6" question="How do I verify if my employees have arrived at their job locations?" >
                  Camelo features 3 methods of clock-in validation for you and your employees: GPS-based, Wi-Fi, and facial recognition. Employees can use a GPS location stamp, access the location's Wi-Fi network, or take a selfie at the location to prove that they've arrived at work.
                </QA>
                <QA id="7" question="What's the GPS clock-in method for?" >
                  It's useful for any business, especially for offsite workers such as caterers or repair workers. They can easily clock in once they've arrived at the location.
                </QA>
                <QA id="8" question="Can I export timesheets to a spreadsheet?" >
                  Yes, Camelo lets you export timesheets to an Excel spreadsheet.
                </QA>
                <QA id="9" question="Can I manage multiple locations with Camelo?" >
                  Yes. You can manage multiple locations in just one app.
                </QA>
              </div>
              <div className="col-12 text-center pt-11">
                <p className="gr-text-9 gr-text-9 gr-text-color">
                  Haven’t got your answer?{" "}
                  <Link to="/contact" className="btn-link">
                    Contact our support now.
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>

      </div>
    </>
  );
};

export default Faq;
