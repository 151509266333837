import React from "react";
import SEO from "../../components/Layout/SEO";
import PageWrapper from "../../components/PageWrapper";
import { Container, Row, Col } from "react-bootstrap";

import Hero from "../../sections/camelo/time-clock/Hero";
import Content1 from "../../sections/camelo/time-clock/Content1";
import Content2 from "../../sections/camelo/time-clock/Content2";
import Content3 from "../../sections/camelo/time-clock/Content3";
import Content4 from "../../sections/camelo/time-clock/Content4";
import Content5 from "../../sections/camelo/time-clock/Content5";
import Content6 from "../../sections/camelo/time-clock/Content6";
import Content7 from "../../sections/camelo/time-clock/Content7";
// import Content8 from "../../sections/camelo/time-clock/Content8";
import Content9 from "../../sections/camelo/time-clock/Content9";
import Features from "../../sections/camelo/time-clock/Features";
import Testimonial from "../../sections/camelo/time-clock/Testimonial";
import Faq from "../../sections/camelo/time-clock/Faq";
import Recap from "../../sections/camelo/time-clock/Recap";
import Fact from "../../sections/camelo/time-clock/Fact";
import CTA from "../../sections/camelo/time-clock/CTA";

const SectionHeader = ({ header, children, className }) => {
  return (
    <Container className={className}>
      <Row className="text-center justify-content-center align-items-center">
        <Col xs="10">
          <h1 className="gr-text-4 mb-8">
            <span className="highlighted0">{header}</span>
          </h1>
          <p className="gr-text-8"> {children} </p>
        </Col>
      </Row>
    </Container>
  );
};

const TimeClockPage = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Time & Attendance Tracking Software | Time Clock App | Camelo"
          description="Streamline clocking in and clocking out for staff, track attendance
            more effectively, and boost your team’s productivity with Camelo
            time tracking software"
        ></SEO>
        <Hero />
        <Features />
        <SectionHeader
          header="A time clock app that makes your life hassle-free"
          className="pt-16 pt-lg-32"
        >
          Camelo eliminates expensive hardware, messy paper time cards, time
          theft, and forgetful mistakes.
        </SectionHeader>
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />

        <SectionHeader header="A timesheet app that streamlines payroll">
          Ditch those messy paper timesheets. Rid yourself of manual time
          tracking work.
          <br />
          Let Camelo record, export, and maintain timesheets for you.
        </SectionHeader>
        <Content5 />
        <Content6 />
        <Content7 />
        {/* <Content8 /> */}

        <SectionHeader header="View real-time attendance at a glance"></SectionHeader>
        <Content9 />

        {/* <Testimonial /> */}
        <Recap />
        {/* <Fact /> */}
        <CTA />
        <Faq />
      </PageWrapper>
    </>
  );
};

export default TimeClockPage;
